<template>
    <datatable :scroll-x="false" :columns="columns">
        <template #tbody="{ classes }">
            <tr v-for="bonus in bonuses.data" :key="bonus.id" :class="classes.tr">
                <td :class="classes.td">
                    <span class="font-medium text-gray-900">{{ bonus.attributes.product_code }}</span>
                </td>
                <td :class="classes.td">{{ bonus.attributes.product_name }}</td>
                <td :class="classes.td">{{ bonus.attributes.bonus_qty }}</td>
            </tr>
        </template>
    </datatable>
</template>

<script>
import { mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request'

export default {
    mixins: [requestMixin],
    data() {
        return {
            bonuses: {
                data: []
            }
        }
    },
    computed: {
        ...mapGetters({
            getOrder: 'orders/getOrder'
        }),
        columns() {
            return [
                { id: 'code', name: 'Kode Barang' },
                { id: 'name', name: 'Nama Barang' },
                { id: 'bonus_qty', name: 'Jumlah Bonus' }
            ]
        }
    },
    methods: {
        async loadOrderBonuses() {
            const [res, error] = await this.request(`/api/v1/orders/${this.getOrder.data.id}/order-detail-bonuses`)

            if (!error) {
                this.bonuses = res
            }
        }
    },
    created() {
        this.loadOrderBonuses()
    }
}
</script>